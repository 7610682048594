@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  @apply w-12 h-12 border-4 border-white rounded-full;
  border-top-color: transparent;
  animation: spinner-spin 1s linear infinite;

  &-sm {
    @apply w-6 h-6;
    border-width: 3px;
    border-top-color: transparent;
  }

  &-primary {
    @apply border-primary-500;
    border-top-color: transparent;
  }
}
